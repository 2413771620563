import { useEffect, useState } from "react";
import {
  addToCart,
  completeCart,
  createPaymentSession,
  getCart,
  getCartId,
  getCustomerId,
  selectPaymentSession,
  selectShippingOption,
  updateCart,
  updateCartItem,
} from "../data";
import { ShippingDataMedusaProps } from "@/types/global";
import { toast } from "react-toastify";

const useCart = () => {
  const [cart, setCart] = useState<any>(null);
  const [order, setOrder] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleVisible = () => {
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 4000);
  };

  const createCartPaymentSession = async (providerId: string) => {
    setLoading(true);
    setError(null);
    try {
      const cart = await createPaymentSession(providerId);
      setCart(cart);
    } catch (err) {
      setLoading(false);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const selectCartPaymentSession = async (providerId: string) => {
    setLoading(true);
    setError(null);
    try {
      const cart = await selectPaymentSession(providerId);
      setCart(cart);
    } catch (err) {
      setLoading(false);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const addVariantToCart = async (
    variantId: string,
    quantity: number,
    productName: string
  ) => {
    setLoading(true);
    setError(null);
    try {
      const cart = await addToCart(variantId, quantity);
      setCart(cart);
      toggleVisible();
      toast(`${productName} added successfully!`, {
        progressStyle: { background: "#009E4C" },
      });
    } catch (err) {
      setLoading(false);
      setError(err.message);
      toast("An error occured. Please try again.", {
        progressStyle: { background: "#C12D3D" },
      });
    } finally {
      setLoading(false);
    }
  };

  const updateLineItem = async (lineItemId: string, quantity: number) => {
    setLoading(true);
    setError(null);
    try {
      const cart = await updateCartItem(lineItemId, quantity);
      setCart(cart);
      const message =
        quantity > 0
          ? "Item updated successfully!"
          : "Item removed successfully!";

      // toast(message, {
      //   progressStyle: { background: "#009E4C" },
      // });
    } catch (err) {
      setLoading(false);
      setError(err.message);
      toast("An error occured. Please try again.", {
        progressStyle: { background: "#C12D3D" },
      });
    } finally {
      setLoading(false);
    }
  };

  const addShippingAddress = async (
    shippingData: ShippingDataMedusaProps,
    billingData: ShippingDataMedusaProps
    // email: string
  ) => {
    const data = { shipping_address: shippingData };
    const dataBilling = { billing_address: billingData };
    setLoading(true);
    setError(null);
    try {
      const cart = await updateCart(data).then(async () => {
        const cart = await updateCart(dataBilling);
        return cart;
      });

      setCart(cart);
      toast("Address added successfully!", {
        progressStyle: { background: "#009E4C" },
      });
    } catch (err) {
      setLoading(false);
      setError(err.message);
      toast("An error occured. Please try again.", {
        progressStyle: { background: "#C12D3D" },
      });
      throw error; // or return { success: false, message: error.message };
    } finally {
      // setLoading(false);
    }
  };

  const addShippingOption = async (shippingOptionId: string) => {
    setLoading(true);
    setError(null);
    try {
      const cart = await selectShippingOption(shippingOptionId);
      // toast("Shipping option added successfully!", {
      //   progressStyle: { background: "#009E4C" },
      // });
      setCart(cart);
    } catch (err) {
      setLoading(false);
      setError(err.message);
      toast("An error occured. Please try again.", {
        progressStyle: { background: "#C12D3D" },
      });
    } finally {
      setLoading(false);
    }
  };

  const finishCart = async () => {
    setLoading(true);
    setError(null);
    try {
      const order = await completeCart();
      if (order) {
        setOrder(order);
      }
      // setCart(cart);
    } catch (err) {
      setLoading(false);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initializeCart = async () => {
      setLoading(true);
      setError(null);
      //check if there is logged in customer, return if there is no customer

      try {
        const customerId = await getCustomerId();
        if (!customerId) return;

        const cartId = await getCartId();
        const cart = await getCart(cartId);
        setCart(cart);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    initializeCart();
  }, []);

  useEffect(() => {
    const quantity = cart?.items?.reduce((sum, item) => sum + item.quantity, 0);
    setTotalQuantity(quantity);
  }, [cart]);

  return {
    cart,
    loading,
    error,
    totalQuantity,
    addVariantToCart,
    updateLineItem,
    addShippingAddress,
    createCartPaymentSession,
    selectCartPaymentSession,
    finishCart,
    addShippingOption,
    order,
    isVisible,
  };
};
export default useCart;
